const config = {
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://api.tailli.latentsearch.io',
    endpoints: {
      customerList: '/api/GetCustomerList',
      apiKeyUnitsForMonth: '/api/ApiKeyUnitsForMonth',
      apiKeyUnitsThisMonth: '/api/ApiKeyUnitsThisMonth',
      apiKeyUnitsUsedLastMonth: '/api/ApiKeyUnitsUsedLastMonth',
      apiKeyUnitsUsedOnDate: '/api/ApiKeyUnitsUsedOnDate',
      // Add other endpoints here
    }
  };
  
  export default config;