import React, { useState } from 'react';
import TailliDashboard from './dashboard';
import config from './config';
import './index.css';

const Feature = ({ title, description }) => (
  <div className="bg-white/10 p-6 rounded-lg shadow-lg">
    <h2 className="text-xl font-semibold mb-2">{title}</h2>
    <p>{description}</p>
  </div>
);

const LoginForm = ({ onLogin }) => (
  <form onSubmit={onLogin} className="space-y-4">
    <input
      type="text"
      name="username"
      placeholder="Username"
      required
      className="w-full p-2 rounded bg-white/20 placeholder-gray-400"
    />
    <input
      type="password"
      name="password"
      placeholder="Password"
      required
      className="w-full p-2 rounded bg-white/20 placeholder-gray-400"
    />
    <button
      type="submit"
      className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    >
      Login
    </button>
  </form>
);

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    const enteredUsername = event.target.username.value;
    const enteredPassword = event.target.password.value;

    try {
      const response = await fetch(`${config.apiBaseUrl}/api/UserCredentialsValidation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: `username=${encodeURIComponent(enteredUsername)}&password=${encodeURIComponent(enteredPassword)}`
      });

      if (response.ok) {
        const { token } = await response.json();
        localStorage.setItem("token", token);
        setIsLoggedIn(true);
        setUsername(enteredUsername);
      } else {
        alert('Invalid username or password');
      }
    } catch (error) {
      console.error("Login failed:", error);
      alert('An error occurred while logging in.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-700 via-indigo-800 to-blue-900 text-white">
      <div className="container mx-auto px-4 py-8">
        <header className="flex justify-between items-center mb-8">
          <div className="flex items-center space-x-4">
            <img src="/tailli_logo.png" alt="Táillí Logo" className="h-16" />
            <div className="text-3xl font-bold">Táillí</div>
          </div>
          {isLoggedIn && (
            <div className="text-sm">Logged in as: {username}</div>
          )}
        </header>

        <main>
          {!isLoggedIn ? (
            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-6">
                <Feature
                  title="Access Control"
                  description="Securely manage who can access your APIs."
                />
                <Feature
                  title="Billing Integration"
                  description="Automate billing with precise usage metrics."
                />
                <Feature
                  title="Usage Tracking"
                  description="Monitor how your APIs are being used in real-time."
                />
                <Feature
                  title="Health Checks"
                  description="Ensure your APIs are healthy with automated checks."
                />
              </div>
              <div className="bg-white/10 p-8 rounded-lg shadow-lg">
                <h1 className="text-3xl font-bold mb-4">Welcome to Táillí</h1>
                <p className="mb-6">API Access control, usage, health, and billing</p>
                <LoginForm onLogin={handleLogin} />
              </div>
            </div>
          ) : (
            <TailliDashboard />
          )}
        </main>

        <footer className="mt-12 text-center text-sm">
          © 2024 Latent Search
        </footer>
      </div>
    </div>
  );
}

export default App;